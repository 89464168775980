<template>
  <div class="home">
    <div class="hero" id="hero">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 col-md-6" style="align-self: center;">
            <div class="hero-content">
              <h1 class="wow fadeInUp" data-wow-delay="0s">CitronWorks Team Management</h1>
              <h4 class="wow fadeInUp" data-wow-delay="0.3s">
                Hire Remote Workers To Grow Your Business 24/7
              </h4>
              <a v-scroll-to="{ element: '.form',  duration: 1500 }" class="learn-btn wow fadeInUp" data-wow-delay="0.4s">Inquire now</a>
            </div>
          </div>
          <div class="col-8 col-md-5">
            <img class="hero-img" src="/images/concierge.png" alt="Hero Image" />
          </div>
        </div>
      </div>
    </div>
    <div class="concierge">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-10 col-md-10">
              <el-card class="mb-4 wow fadeInUp" data-wow-delay="0.6s" style="position: relative; border: none;" :body-style="{ padding: '50px 50px 30px' }">
                <!-- <h5 class="ribbon">
                  $399
                </h5> -->
                <h4 class="bold orange-text text-center ribboned">Welcome to CitronWorks Team Management!</h4>
                <p>With CitronWorks Team Management, we find and manage a remote team for you.</p>
                <p>We find freelancers and remote workers who offer copywriting, web development, customer service, personal assistance, social media marketing, and so much more.</p>
                <p>No fuss. Just great team members.</p>
                <p>We bring on skilled, qualified staff who will help you grow your business. Our proven approach is a smart way for firms looking for that extra edge to access professional workforce support.</p>
                <p>That’s CitronWorks Team Management.</p>
                <p class="mb-4">We believe that technology is at the heart of a good outsourcing practice. Basecamp, Tickspot, Zoom, and other cloud tools allow the team members to work effectively alongside your team. Having your own dedicated virtual team with the ability to freely communicate and train them means they’ll fit into your business’s own culture and values.</p>
              </el-card>
            </div>
          </div>
        </div>
    </div>
    <div class="set-up">
      <div class="container">
        <div class="row">
          <div class="col-lg-4 col-md-4">
            <el-card class="h-100 wow fadeInUp" data-wow-delay="0s" :body-style="{ padding: '30px', position: 'relative' }">
              <h4 class="bold orange-text bordered">Initial Setup</h4>
              <ol>
                <li class="mt-3"> <span class="single-line-lg">One of our CitronWorks Team Managers contacts you to get more detail about your needs.</span></li>
                <li class="mt-3"> Once you approve all the details, your team manager will conduct the search and supply you with the top candidates who meet your requirements.</li>
                <li class="mt-3"><span class="single-line">We can even negotiate rates for you.</span></li>
                <li class="mt-3"><span class="single-line-md">You can interview the shortlisted candidates, or we can choose for you.</span></li>
              </ol>
              <!-- <p class="mt-0 mb-4">Setup charge is $799, for which CitronWorks finds, hires, and sets up a new remote worker. </p> -->
            </el-card>
          </div>
          <div class="col-lg-4 col-md-4">
            <el-card class="h-100 wow fadeInUp" data-wow-delay="0.2s" :body-style="{ padding: '30px', position: 'relative' }">
              <h4 class="bold orange-text bordered">Ongoing Management</h4>
              <ol>
                <li class="mt-3"><span class="single-line-lg">The remote workers hired are your team members for you to train and to interact with daily.</span></li>
                <li class="mt-3 mb-3"><span class="single-line-lg">If you’re too busy to manage them, CitronWorks Team Management provides:</span></li>
                <ul>
                  <li>Timesheet and task reporting and management</li>
                  <li>Regular catchup with staff, including daily and weekly monitoring, and monthly reporting</li>
                  <li>Ensure that the staff and staff/client relationship is running smoothly</li>
                </ul>
              </ol>
            </el-card>         
          </div>
          <div class="col-lg-4 col-md-4">
            <el-card class="h-100 wow fadeInUp" data-wow-delay="0.4s" :body-style="{ padding: '30px', position: 'relative' }">
              <h4 class="bold orange-text bordered">Pricing</h4>
              <ol>
                <li class="mt-3">Setup charge is $799, for which CitronWorks finds, hires, and sets up a new remote worker.</li>
                <li class="mt-3">There is a monthly fee of $100 per company irrespective of the number of remote workers</li>
                <li class="mt-3">There is a 20%-30% charge of the monthly compensation paid to each remote worker.  This includes CitronWorks managing the workers, making payments and replacement as needed.</li>
              </ol>
            </el-card>
          </div>
        </div>
      </div>
    </div>
                
    <div class="form beta" id="form">
      <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-6 col-md-8 wow fadeInUp">
            <hr>
            <el-card :body-style="{ padding: '20px 30px' }">
              <el-form ref="form" :model="signup">
                <el-row :gutter="20" type="flex" justify="center">
                  <el-col :xs="24" :lg="17" class="text-center">
                    <h4 class="orange-text mb-3 mt-3 bold">CitronWorks Team Management Inquiry</h4>
                    <p class="mb-4">Please complete the form below. We will get back to you to get more details about your needs.</p>
                  </el-col>
                </el-row>
                <el-row :gutter="20" >
                  <el-col :xs="24" :md="12" :sm="12" class="is-required">
                    <el-form-item label="First Name" prop="first_name">
                      <el-input v-model="signup.first_name"></el-input>
                    </el-form-item>

                  </el-col>
                  <el-col :xs="24" :md="12" :sm="12">
                    <el-form-item label="Last Name" prop="last_name">
                      <el-input v-model="signup.last_name"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :md="12" :sm="12" class="is-required">
                    <el-form-item label="Email Address" prop="email">
                      <el-input v-model="signup.email" type="email"></el-input>
                    </el-form-item>

                  </el-col>
                  <el-col :xs="24" :md="12" :sm="12">
                    <el-form-item label="Company" prop="company">
                      <el-input v-model="signup.company"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :md="12" :sm="12">
                    <el-form-item label="Company Website" prop="company_website">
                      <el-input v-model="signup.company_website"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :md="12" :sm="12">
                    <el-form-item label="Phone" prop="phone">
                      <el-input v-model="signup.phone"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24">
                    <el-form-item label="Message" prop="message" class="is-required">
                      <el-input
                        type="textarea"
                        :rows="4"
                        v-model="signup.message">
                      </el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24">
                    <p class="small">* Required</p>
                  </el-col>
                  <input type="hidden" v-model="signup.referer" />
                  <!--Show only when submitted-->
                   <el-col :xs="24">
                    <el-alert v-if="status ==='success'" class="mb-3 mt-3"
                      :title="signup.successMessage"
                      type="success"
                      effect="dark">
                    </el-alert>
                  </el-col>
                  <!--Show when there's an error-->
                  <el-col :xs="24">
                    <el-alert v-if="error"
                      title="The following is required:"
                      type="error"
                      :closable="click=false"
                      @close="true"
                      class="signup-alert mb-3 mt-3">
                      <li v-for="first in error['citronworkers_request.first_name']"
                          :key="first">
                        <i class="el-icon-error"></i> {{ first }}
                      </li>
                      <li v-for="last in error['citronworkers_request.last_name']" :key="last">
                        <i class="el-icon-error"></i> {{ last }}
                      </li>
                      <li v-for="email in error['citronworkers_request.email']" :key="email">
                        <i class="el-icon-error"></i> {{ email }}
                      </li>
                      <li v-for="message in error['citronworkers_request.message']" :key="message">
                        <i class="el-icon-error"></i> {{ message }}
                      </li>
                      <li v-for="phone in error['citronworkers_request.phone']" :key="phone">
                        <i class="el-icon-error"></i> {{ phone }}
                      </li>
                      <li v-for="company in error['citronworkers_request.company']" :key="company">
                        <i class="el-icon-error"></i> {{ company }}
                      </li>
                      <li v-for="company_website in error['citronworkers_request.company_website']" :key="company_website">
                        <i class="el-icon-error"></i> {{ company_website }}
                      </li>
                      <!-- <li v-for="referer in error['concierge_request.referer']" :key="referer">
                        <i class="el-icon-error"></i> {{ referer }}
                      </li> -->
                    </el-alert>
                  </el-col>
                  <el-col :xs="24">
                    <el-form-item class="text-center buttons mt-1">
                      <el-button type="warning" @click="citronworkers(signup)">
                        Submit
                        <i class="el-icon-loading" v-show="status === 'loading'"></i>
                      </el-button>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form>
            </el-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { WOW } from 'wowjs'
import {mapGetters, mapActions} from 'vuex';
import $ from 'jquery'

export default {
  components: {
      // TermsOfService,
      // PrivacyPolicy,
    },
  data() {
    return {
      tos: false,
      pp: false,
    }
  },
  mounted () {
    new WOW().init()
    $(function () {
      $(document).scroll(function () {
        var $alert = $(".alert");
        $alert.toggleClass('scrolled', $(this).scrollTop() > $alert.height());
      });
    });
  },
  computed: {
    ...mapGetters({
       error: 'getCitronworkersErr'
      , signup:'getCitronworkers'
      , status: 'authStatus'
    }),
  },
  watch: {
    
  },
  methods: {
    ...mapActions({
      citronworkers: 'citronworkersRequest',
    }),
    checkingRequired() {
      return this.status === 'success' ? false : true;
    }
  }
}
</script>

<style lang="scss" scoped>
.home {
  background: #f3f3f3;
}
button:active, button:focus {
  outline: coral;
}
.form.beta {
  padding: 80px 0;
  // background: #f9f9f7;
  background: linear-gradient(to right, rgba(232, 119, 33, .1), rgba(23, 21, 20, 0.4)), url(/images/signup.jpg) no-repeat center right;
  background-size: cover;

  h4 {
    letter-spacing: 1.1px;
    line-height: 1.4;
  }
}

.alert button{
  position: absolute;
  top: 15px;
  right: 15px;
  padding: 0;
}
.alert button:active, .alert button:focus{
  outline: none;
}
.learn-btn {
  color: #ffffff !important;
  background-color: #E87722;
  border: 1px solid #E87722;
}
.concierge {
  padding: 60px 0 40px 0;
  // border-bottom: 1px solid #d3d3d3;
  
  
  h4.bordered {
    margin-top: 40px;
    padding-top: 30px;
    border-top: 1px solid #f6f6f6;
    margin-bottom: 1.75rem;
  }
  p {
    font-size: 0.9rem;
    line-height: 1.7;
    letter-spacing: 1.2px;
  }

  .el-card.mb-4 {
    border-radius: 5px !important;
  }
  h4{
    line-height: 1.4;
    margin-bottom: 2rem;
    letter-spacing: 1.1px;
  }
}
.set-up {
  background: white;

  .el-card {
    border-radius: 5px !important;
  }
  h4{
    line-height: 1.4;
    // margin-bottom: 3.5rem;
    margin-bottom: 1.8rem;
    letter-spacing: 1.1px;
    // margin-left: 110px;
  }

  .card-dropcap {
    position: absolute;
    float: left;
    font-weight: bold;
    font-size: 8rem;
    margin-right: 0.5rem;
    font-family: "Abril Fatface", serif;
    line-height: 1;
    color: #f3f3f3;
    top: -20px;
    left: -5px;
  }
  ol {
    list-style: none;
    margin-left: 20px;
    padding: 0;
  }
  ol li {
    width: 100%;
    font-size: 0.9rem;
    margin-bottom: 1rem;
    line-height: 1.7;
    letter-spacing: .6px;
  }
  p {
   line-height: 1.7;
    letter-spacing: .6px; 
  }
  ul {
    padding-left: 20px;
  }
  ul li {
    margin-bottom: .5rem;
  }
  ol li .dropcap {
    float: left;
    font-weight: bold;
    font-size: 2rem;
    margin-right: 0.5rem;
    font-family: 'Abril Fatface', serif;
    line-height: 1;
    color: gray;
  }
  p {
    letter-spacing: .6px;
  }
  ol>li::before {
    content: "";
    font-family: element-icons !important;
    display: inline-block;
    margin-left: -1.3em;
    width: 1.5em;
    color: #91C145;
  }
  .banner {
    background: #E87722;
    color: white;
    font-weight: 600;
    border-color: #E87722;
  }
  // ol li::before {
  //   content: "0" counter(my-awesome-counter);
  //   font-weight: bold;
  //   font-size: 3rem;
  //   margin-right: 0.5rem;
  //   font-family: 'Abril Fatface', serif;
  //   line-height: 1;
  // }
}
.set-up {
  padding: 70px 0;
}

hr {
  margin: 0;
  border-top: #e87722 2px solid;
}
.home {
  overflow: hidden;
}
.terms {
  color: inherit;
  text-decoration: underline;
  padding: 0;
}
// .hero {
//   background: #f9f9f7;
//   padding
//   // background-image: url('/images/team2.png');
//   // border-bottom: 1px solid lightgray;
//   // background-repeat: no-repeat;
//   // background-size: 62%;
//   // height: 100%;
//   // background-position: 107% center;
// }
.hero {
  padding-top: 80px;
  background: #f9f9f7;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 5px 1px rgba(0, 0, 0, 0.1); 
}
.hero-margin {
  padding-top: 140px; 
}

.hero-margin {
  padding-top: 140px; 
}

.hero-img{
  max-width: 100%;
  padding: 0 0 0 40px;
}
.hero-content h1 {
  color: #f89827;
  font-size: 46px;
}
.hero-content h4 {
  font-size: 1.155rem;
  color: #576874;
  font-weight: 400;
  line-height: 1.6;
  margin: 0 0 25px 0;
}
.alert.fixed {
  position: fixed;
  z-index: 2003;
  width: 100%;
  // transition: .2s;
  // transition-timing-function: ease-out;
}

.home .el-alert__content li {
  list-style: none;
  padding-left: 8px;
}
@media (min-width: 768px) {
  .alert.scrolled {
    top: 61px;
    transition-property: top;
    transition: .2s;
    transition-timing-function: ease-out;
  }
  .about {
    padding: 50px 30px;
  }
  .visible-xs {
    display: none;
  }
}
@media (min-width: 900px) { 
  .single-line-md {
    // line-height: 48px;
  }
}

@media (min-width: 993px) {
  .hero-content h1 {
      margin: 0 10px 20px 0;
  }
  .visible-sm {
    display: none;
  }
  .about {
    padding: 100px 0px;
  }
}
@media (min-width: 1200px) {
  .single-line-lg {
    // line-height: 48px;
  }
}

@media (max-width: 1199px) {

}
@media (max-width: 992px) {
  
  .hidden-sm {
    display: none;
  }
  #pitch {
    margin-bottom: 10px;
  }
  .cta-sub {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .hero {
    padding-top: 50px;
  }
  .hero-margin {
    padding-top: 100px;
  }
  .hero-content h1 {
    font-size: 32px;
  }
  .hero-content h4 {
    font-size: 1.05rem;
  }
  .set-up .col-md-6 {
    margin-bottom: 25px;
    padding: 0 25px;
  }
  .set-up {
    padding: 40px 0 15px 0;
  }
}
@media (max-width: 767px) {
  .concierge {
    padding: 40px 10px 20px;
  }
  .set-up .col-md-4 {
    margin-bottom: 30px;
  }
  .alert.fixed {
    padding-right: 0;
    padding-left: 0;

    p { 
      font-size: 0.8rem;
      line-height: 1.5;
    }
  }
  .form.beta {
    padding: 15px 0;
  }
  .visible-xs {
    display: block;
  }
  .hidden-xs {
    display: none;
  }
  .hero {
    padding-top: 40px;
  }
  .hero-margin {
    padding-top: 100px;
  }
  .hero-content {
    padding: 15px 0;
    text-align: center;

    h4, h1{
      text-align: center
    }
  }
  .hero-content h1 {
    font-size: 2rem;
    margin-bottom: 25px;
  }
  .hero-img {
    padding: 0 50px;
  }
  .about {
    padding: 40px 25px;
    overflow: hidden;
  }
  .learn-btn {
    margin: 0 auto 30px !important;
  }
  .about-content h3 {
    font-size: 1.1rem;
  }
  .about-content h2 {
    font-size: 1.75rem;
  }
  .freelancer-row, .employer-row {
    padding: 25px 10px;
    margin: 0 10px 50px 10px;
  }
  .pitch-content h1 {
    margin-top: 10px;
  }
  .signup-form {
    margin: 0 15px;
  }
}
@media (max-width: 320px) {
  
  .concierge h4 {
    font-size: 1.25rem
  }
  .concierge p, .concierge li {
    letter-spacing: 1px;
  }
}
.ribbon {
  margin: 0;
  padding: 0;
  background: coral;
  color:white;
  padding:.5em 1em;
  position: absolute;
  top:0;
  right:0;
  transform: translateX(30%) translateY(0%) rotate(45deg);
  transform-origin: top left;
  font-weight: bold;
  letter-spacing: 1px;
  font-size: 1rem;
}
.ribbon:before,
.ribbon:after {
  content: '';
  position: absolute;
  top:0;
  margin: 0 -1px; /* tweak */
  width: 100%;
  height: 100%;
  background: coral;
}
.ribbon:before {
  right:100%;
}

.ribbon:after {
  left:100%;
}
.single-line {
  // line-height: 48px;
}
</style>
